import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// import img1 from '../../images/portfolio/zapoint/01.png';
// import img2 from '../../images/portfolio/zapoint/02.png';

// import zlogo from '../../images/portfolio/identity/zapoint-logo.png';
// import lclogo from '../../images/portfolio/identity/lc-logo.png';
// import palogo from '../../images/portfolio/identity/pa-logo.png';
// import smlogo from '../../images/portfolio/identity/sm-logo.png';


const Zapoint = () => {

	const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "portfolio/identity/zapoint-logo.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			logoSm: file(relativePath: { eq: "portfolio/identity/sm-logo.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			logoLc: file(relativePath: { eq: "portfolio/identity/lc-logo.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			logoPa: file(relativePath: { eq: "portfolio/identity/pa-logo.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			img1: file(relativePath: { eq: "portfolio/zapoint/01.png" }) {
				childImageSharp {
					fluid(maxWidth: 700) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			img2: file(relativePath: { eq: "portfolio/zapoint/02.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)

	return(
		<Layout>
			<SEO title="Case Studies: Zapoint" />
			<div className="case-study">
				<div className="intro">
					<div className="brand">
						<Img className='mx-auto' width="160" fixed={data.logo.childImageSharp.fixed} />
					</div>
					<h1 className='page-title display'>_Zapoint</h1>
					<div className="base-img base-img-sf">
						<Img fluid={data.img1.childImageSharp.fluid} />
					</div>
				</div>

				<div className="row text-center">
					<div className="col-md-7 mx-auto">
						<p className='text-primary-dark mb-4'>The Axe Edge team worked with Zapoint for a number of years, helping to develop their applications along with branding and marketing materials to drive sales.</p>
						<ul>
							<li className='label label-primary mr-1'>Web Design</li>
							<li className='label label-primary mr-1'>UX Design</li>
							<li className='label label-primary mr-1'>Branding</li>
							<li className='label label-primary mr-1'>Full-Stack Development</li>
						</ul>
					</div>
				</div>

				<div className="content-img">
					<ul className="zapoint-list-logos">
						<li><Img fixed={data.logoSm.childImageSharp.fixed} /></li>
						<li><Img fixed={data.logoLc.childImageSharp.fixed} /></li>
						<li><Img fixed={data.logoPa.childImageSharp.fixed} /></li>
					</ul>
				</div>

                <div className='content-img'>
					<Img fluid={data.img2.childImageSharp.fluid} />
				</div>
			</div>
		</Layout>
	)
}



export default Zapoint
